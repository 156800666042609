<template>
  <div class="join">
    <SectionWithBg
      single-line-heading
      heading-top
      growing-body
      min-width-heading
      heading-position="left"
    >
      <template #heading> Join {{ event.name }}</template>
      <template #body>
        <div class="join__wrapper">
          <form @submit.prevent="submitTicketCode" class="join__form">
            <Input
              v-model="form.code.value"
              :error="form.code.error"
              type="text"
              name="join_code"
              label="Ticket code"
              @input="clearError('code', form)"
              @blur="validateField('code', form)"
            />
            <Input
              v-model="form.email.value"
              :error="form.email.error"
              type="text"
              name="join_email"
              label="Email"
              @input="clearError('email', form)"
              @blur="validateField('email', form)"
            />
            <transition name="fade">
              <Error v-if="codeError" class="join__error">
                <template #heading>{{ codeError }}</template>
                <template #body>
                  Please try again or contact us at
                  <a
                    :href="`mailto:${config.helpEmail}?subject=Сode Register Error - ${codeError}`"
                    class="error__link"
                  >
                    {{ config.helpEmail }}
                  </a>
                </template>
              </Error>
            </transition>
            <Button
              v-show="!loading"
              fullWidth
              :loading="loading"
              class="join__button"
            >
              register code
            </Button>
            <Preloader :loading="loading" />
          </form>
          <p class="join__text">
            If you don’t have an access code you can
            <Link :link="`/${$route.params.id}/checkout`">
              buy a ticket now</Link
            >
          </p>
        </div>
      </template>
    </SectionWithBg>
  </div>
</template>

<script>
import SectionWithBg from "@/components/common/SectionWithBg";
import Preloader from "@/components/common/Preloader";
import Input from "@/components/common/Input";
import Button from "@/components/common/Button";
import Link from "@/components/common/Link";
import Error from "@/components/common/Error";
import {
  validateField,
  validateForm,
  clearError,
} from "@/assets/js/validation";
import { transformForm } from "@/assets/js/utils";
import { mapActions, mapGetters } from "vuex";
import config from "../../../public/config.json";

export default {
  name: "Join",
  props: {},
  components: {
    Preloader,
    SectionWithBg,
    Input,
    Button,
    Link,
    Error,
  },
  data: () => ({
    config,
    loading: false,
    codeError: null,
    form: {
      code: {
        value: "",
        error: "",
        rules: ["required"],
      },
      email: {
        value: "",
        error: "",
        rules: ["required", "email"],
      },
    },
  }),
  computed: {
    ...mapGetters({
      event: "getCurrentEventDetails",
    }),
  },
  mounted() {
    this.form.code.value = this.$route.params.code || "";

    if (
      !Object.keys(this.event).length ||
      this.event.id !== this.$route.params.id
    ) {
      this.loading = true;
      this.getEvent(this.$route.params.id)
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  methods: {
    transformForm,
    validateField,
    validateForm,
    clearError,
    ...mapActions(["getEvent", "PUT"]),
    submitTicketCode() {
      this.codeError = null;
      const isValid = validateForm(this.form);
      if (!isValid) return;

      this.loading = true;
      const formData = this.transformForm(this.form);

      this.PUT({
        formRoute: false,
        route: `/ticketing/public/event/${this.$route.params.id}/code/${formData.code}/valid`,
        data: formData,
      })
        .then((res) => {
          localStorage.setItem(
            "ticket",
            JSON.stringify({
              id: res.data.id,
              title: res.data.title,
              description: res.data.description,
            })
          );
          this.$store.commit("setUserEmail", formData.email);
          this.$store.commit("setUserCode", formData.code);
          this.$router.replace(`/${this.$route.params.id}/register`);
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.data.msg === "Validation failure") {
            this.codeError = err.response.data.errors[0];
          } else {
            this.codeError = err.response.data.msg;
          }
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.join {
  padding-top: 106px;

  &__button {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  &__error {
    text-align: center;
    margin-top: 5%;
  }

  @media (min-width: $media-sm) {
    &__wrapper {
      max-width: 374px;
    }
  }

  @media (min-width: $media-sm) {
    &__wrapper {
      max-width: 462px;
    }
  }
}
</style>
